/** @jsx jsx */
import { Container, Heading, jsx } from 'theme-ui'
import { object, string } from 'prop-types'

import Media from '~/components/Media'
import IntroTransition from '~/components/IntroTransition'
import renderRichText from '~/content/renderRichText'
import Section from '~/components/Section'
import { ContentfulRichTextPropType } from '~/types'

const DISPLAY_NAME = 'TextMediaOverlapHeroSection'

const PROP_TYPES = {
  body: ContentfulRichTextPropType,
  elementId: string,
  heading: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  media: object,
}

const Component = ({
  body,
  elementId,
  heading,
  media,
}) => (
  <Section id={elementId}>
    <Container>
      <div
        sx={{
          display: 'grid',
          rowGap: [4, null, 'unset'],
          gridTemplateColumns: ['100%', null, 'repeat(3, 1fr)'],
          gridTemplateRows: [null, null, '1fr 3fr 1fr'],
        }}
      >
        <div
          sx={{
            gridColumn: [null, null, '1 / 3'],
            gridRow: [null, null, '1 / 3'],
            maxWidth: 'containerSmall',
          }}
        >
          <IntroTransition>
            <Heading
              as="h1"
              variant="headingHero"
              mb="2"
            >
              {heading}
            </Heading>
            {renderRichText(body, { style: 'prospect' })}
          </IntroTransition>
        </div>
        <div
          sx={{
            gridColumn: [null, null, '2 / 4'],
            gridRow: [null, null, '2 / 4'],
            zIndex: [null, null, -1],
          }}
        >
          <IntroTransition direction="none">
            <Media media={media} />
          </IntroTransition>
        </div>
      </div>
    </Container>
  </Section>
)

Component.displayName = DISPLAY_NAME
Component.propTypes = PROP_TYPES

export default Component
